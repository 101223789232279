<template>
  <div class="flex-center full-width">
    <div :style="bannerWidth">
      <div v-if="isShowing && banner && banner.length>0"
           class="full-width" name="Banner">
        <image-slider :listData="banner" :height="imgBannerHeight"
                      :style="[imageSliderStyle]"
                      :useRatio="useRatio"
                      :imageRates="imageRates"
                      :imgPaddingTb="imgPaddingTb"
                      :imgPaddingLr="imgPaddingLr"
                      :autoplay="autoplay"
                      :vertical="vertical"
                      :autoplaySpeed="autoplaySpeed"
                      :slidesToScroll="slidesToScroll"
                      :arrow="arrow"
                      :controller="controller"
                      :indicator="indicator"
                      :indicatorType="indicatorType"
                      :arrowLeftStyle="arrowLeftStyle"
                      :arrowRightStyle="arrowRightStyle"
                      :customIndicatorStyle="customIndicatorStyle"
                      :customCurrentIndicatorStyle="customCurrentIndicatorStyle"
                      :slidesToShow="slidesToShow"></image-slider>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageSlider from "../module/ImageSlider";
  export default {
    name: "Banner",
    components: {ImageSlider},
    props: {
      // PC모드에서 fullWidth 또는 pcMaxWidth 적용
      fullWidth: {
        type: Boolean,
        default: true
      },
      indicatorType: {
        type: String,
        default: 'dot'
      },
      indicator: {
        type: Boolean,
        default: false,
      },
      // custom height
      height: {
        type: Number,
        required: false
      },
      // custom width
      width: {
        type: Number,
        required: false
      },
      // custom ratio (cf. height = width * ${ratio})
      ratio: {
        type: Number,
        required: false
      },
      autoplay: {
        type: Boolean,
        default: true
      },
      autoplaySpeed: {
        type: Number,
        default: 3000,
      },
      slidesToScroll: {
        type: Number,
        default: 1,
      },
      slidesToShow: {
        type: Number,
        default: 1,
      },
      bannerId: {
        type: Number,
        default: 0
      },
      useRatio: {
        type: Boolean,
        default: true
      },
      arrow: {
        type: Boolean,
        default: false
      },
      controller: {
        type: Boolean,
        default: false
      },
      arrowLeftStyle: {
        type: Object,
        required: false
      },
      arrowRightStyle: {
        type: Object,
        required: false
      },
      customIndicatorStyle: {
        type: Object,
        required: false
      },
      customCurrentIndicatorStyle: {
        type: Object,
        required: false
      },
      vertical: {
        type: Boolean,
        default: false
      },
      imgPaddingTb: {
        type: Number,
        default: 0
      },
      imgPaddingLr: {
        type: Number,
        default: 0
      },
      imgRadius: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        banner: [],
        bannerRatio: 0,
        imgBannerRatio: 0,
        bannerHeight: 200,
        imgBannerHeight: 200,
        lpVideo: [],
        urlVideo: [],
        screenWidth: window.innerWidth,
        isShowing: false,
        imageWidth: 0,
        imageRates: undefined
      }
    },
    created() {
      this.init();
      this.getData();
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.setScreenWidth();
          this.bannerStyle();
          this.imgBannerStyle();
        });
      })
    },
    methods: {
      setScreenWidth() {
        this.screenWidth = window.innerWidth;
        if(!this.fullWidth) {
          this.screenWidth = Number(this.pcMaxWidth.width.replace('px',''));
        }
      },
      init() {
        this.deviceWidth = this.$store.getters.deviceWidth;
        this.setScreenWidth();
      },
      getData() {
        let url = 'public/banner/list'
        if(this.bannerId && this.bannerId>0) {
          this.getBanner(`public/banner/${this.bannerId}`);
        } else {
          if(this.bannerId===0) {
            if(this.$store.getters.device==='pc') {
              url += '/pc'
            }
            this.getBannerList(url);
          }
        }
      },
      getBanner(url) {
        this.$axios.get(url).then(res=>{
          this.banner = [];
          this.lpVideo = [];

          let date = new Date().getTime();
          let start_date = new Date(res.data.start_date).getTime();
          let end_date = new Date(res.data.end_date).getTime();

          if (date >= start_date && date <= end_date) {
            this.isShowing = true;
            this.$emit('isShowing', true)
          } else {
            this.isShowing = false;
            this.$emit('isShowing', false)
          }

          // image banner
          if(res.data.type === 0) {
            for(let i=0; i<res.data.imgs.length; i++) {
              let img = res.data.imgs[i];
              this.banner.push({
                url: img,
                link: res.data.links[i]
              });
            }
            this.imgBannerRatio = res.data.pc ? res.data.ratio_pc : res.data.ratio;
            this.imgBannerStyle();
          }
          // video banner
          else if(res.data.type >= 1) {
            for(let i=0; i<res.data.imgs.length; i++) {
              let img = res.data.imgs[i];
              let video = res.data.type === 1 ? 'lpVideo' : 'urlVideo';
              this[video].push({
                url: img,
                link: res.data.links[i]
              });
            }
            this.bannerRatio = res.data.pc ? res.data.ratio_pc : res.data.ratio;
            this.bannerStyle();
          }

          setTimeout(() => {
            this.$emit('getBanner');
          }, 700);
        }).catch(()=>{
          setTimeout(() => {
            this.$emit('getBanner');
          }, 700);
        });
      },
      getBannerList(url) {
        this.$axios.get(url).then(res=>{
          if (res.data.start_date && res.data.end_date) {
            let date = new Date().getTime();
            let start_date = new Date(res.data.start_date).getTime();
            let end_date = new Date(res.data.end_date).getTime();

            if (date >= start_date && date <= end_date) {
              this.isShowing = true;
              this.$emit('isShowing', true)
            } else {
              this.isShowing = false;
              this.$emit('isShowing', false)
            }
          } else {
            this.isShowing = true;
            this.$emit('isShowing', true)
          }

          if(res.data.length>0) {
            let imgBanner = res.data.filter(item => item.data[0].type===0);
            if(imgBanner.length>0) {
              this.imgBannerRatio = imgBanner[0].ratio;
              this.banner = imgBanner[0].data;
              this.imgBannerStyle();
            }
            let videoBanner = res.data.filter(item => item.data[0].type === 1 || item.data[0].type === 2);
            if(videoBanner.length>0) {
              this.bannerRatio = videoBanner[0].ratio;
              if (videoBanner[0].data[0].type === 1) this.lpVideo = videoBanner[0].data;
              else if (videoBanner[0].data[0].type === 2) this.urlVideo = videoBanner[0].data;
              this.bannerStyle();
            }

          }
          setTimeout(() => {
            this.$emit('getBanner');
          }, 700);
        }).catch(()=>{
          setTimeout(() => {
            this.$emit('getBanner');
          }, 700);
        });
      },
      bannerStyle() {
        let deviceWidth = '';
        /* PC */
        if(this.$store.getters.device==='pc') {
          deviceWidth = this.screenWidth;
          if (this.width) deviceWidth = this.width;

          // custon ratio
          if (this.ratio!==undefined) {
            this.bannerHeight = deviceWidth * this.ratio;
          }
          else {
            // 3:1
            if(this.bannerRatio===0) this.bannerHeight = deviceWidth * 1/3;
            // 4:1
            else if(this.bannerRatio===1) this.bannerHeight = deviceWidth * 1/4;
            // 5:1
            else if(this.bannerRatio===2) this.bannerHeight = deviceWidth * 1/5;
            // 8:1
            else if(this.bannerRatio===3) this.bannerHeight = deviceWidth * 1/8;
            // 1:1
            else if(this.bannerRatio===4) this.bannerHeight = deviceWidth;
            // 2:1
            else if(this.bannerRatio===5) this.bannerHeight = deviceWidth * 1/2;
          }
        }
        /* Mobile */
        else {
          deviceWidth = this.deviceWidth;
          if (this.width) deviceWidth = this.width;

          // custon ratio
          if (this.ratio!==undefined) {
            this.bannerHeight = deviceWidth * this.ratio;
          }
          else {
            // 4:3
            if(this.bannerRatio===0) this.bannerHeight = deviceWidth * 3/4;
            // 16:9
            else if(this.bannerRatio===1) this.bannerHeight = deviceWidth * 9/16;
            // 2.35:1
            else if(this.bannerRatio===2) this.bannerHeight = deviceWidth * 1/2.35;
            // 4:1
            else if(this.bannerRatio===3) this.bannerHeight = deviceWidth * 1/4;
            // 8:1
            else if(this.bannerRatio===4) this.bannerHeight = deviceWidth * 1/8;
            // 1:1
            else if(this.bannerRatio===5) this.bannerHeight = deviceWidth;
            // 1.3:1
            else if(this.bannerRatio===6) this.bannerHeight = deviceWidth * 1/1.3;
          }
        }
        if (this.height) {
          this.bannerHeight = this.height;
        }
      },
      imgBannerStyle() {
        let deviceWidth = '';
        /* PC */
        if(this.$store.getters.device==='pc') {
          deviceWidth = this.screenWidth;
          if (this.width) deviceWidth = this.width;

          this.imageWidth = deviceWidth;

          // custom ratio
          if (this.ratio!==undefined) {
            this.imgBannerHeight = deviceWidth * this.ratio;
          }
          else {
            // 3:1
            if(this.imgBannerRatio===0) {
              this.imgBannerHeight = deviceWidth * 1/3;
              this.imageRates = {ratio: '3:1'}
            }
            // 4:1
            else if(this.imgBannerRatio===1) {
              this.imgBannerHeight = deviceWidth * 1/4;
              this.imageRates = {ratio: '4:1'}
            }
            // 5:1
            else if(this.imgBannerRatio===2) {
              this.imgBannerHeight = deviceWidth * 1/5;
              this.imageRates = {ratio: '5:1'}
            }
            // 8:1
            else if(this.imgBannerRatio===3) {
              this.imgBannerHeight = deviceWidth * 1/8;
              this.imageRates = {ratio: '8:1'}
            }
            // 1:1
            else if(this.imgBannerRatio===4) {
              this.imgBannerHeight = deviceWidth;
              this.imageRates = {ratio: '1:1'}
            }
            // 2:1
            else if(this.imgBannerRatio===5) {
              this.imgBannerHeight = deviceWidth * 1/2;
              this.imageRates = {ratio: '2:1'}
            }
            // 풀스크린
            else if(this.imgBannerRatio===6) {
              this.imgBannerHeight = window.innerHeight;
              this.imageRates = {ratio: 'fullscreen'}
            }
          }
        }
        /* Mobile */
        else {
          deviceWidth = this.deviceWidth;
          if (this.width) deviceWidth = this.width;

          this.imageWidth = deviceWidth;

          // custom ratio
          if (this.ratio!==undefined) {
            this.imgBannerHeight = deviceWidth * this.ratio;
          }
          else {
            // 4:3
            if(this.imgBannerRatio===0) {
              this.imgBannerHeight = deviceWidth * 3/4;
              this.imageRates = {ratio: '4:3'}
            }
            // 16:9
            else if(this.imgBannerRatio===1) {
              this.imgBannerHeight = deviceWidth * 9/16;
              this.imageRates = {ratio: '16:9'}
            }
            // 2.35:1
            else if(this.imgBannerRatio===2) {
              this.imgBannerHeight = deviceWidth * 1/2.35;
              this.imageRates = {ratio: '2.35:1'}
            }
            // 4:1
            else if(this.imgBannerRatio===3) {
              this.imgBannerHeight = deviceWidth * 1/4;
              this.imageRates = {ratio: '4:1'}
            }
            // 8:1
            else if(this.imgBannerRatio===4) {
              this.imgBannerHeight = deviceWidth * 1/8;
              this.imageRates = {ratio: '8:1'}
            }
            // 1:1
            else if(this.imgBannerRatio===5) {
              this.imgBannerHeight = deviceWidth;
              this.imageRates = {ratio: '1:1'}
            }
            // 1.3:1
            else if(this.imgBannerRatio===6) {
              this.imgBannerHeight = deviceWidth * 1/1.3;
              this.imageRates = {ratio: '1.3:1'}
            }
            // 풀스크린
            else if(this.imgBannerRatio===7) {
              this.imgBannerHeight = window.innerHeight;
              this.imageRates = {ratio: 'fullscreen'}
            }
          }
        }
        if (this.height) {
          this.imgBannerHeight = this.height;
        }
      }
    },
    computed: {
      bannerWidth() {
        return this.fullWidth ? { width: '100%' } : this.pcMaxWidth;
      },
      imageSliderStyle() {
        let deco = {
          width: `${this.imageWidth}px`,
          height: `${this.imgBannerHeight + this.imgPaddingTb*2}px`
        }
        return deco;
      },
      wrapperStyle() {
        return {
          paddingBottom: `${this.bannerHeight}px`,
          height: this.bannerHeight+'px',
          overflow: 'hidden',
        }
      }
    },
    watch: {
      '$store.getters.deviceWidth'() {
        this.deviceWidth = this.$store.getters.deviceWidth;
      },
      bannerId() {
        this.banner = [];
        this.init();
        this.getData();
      }
    }
  }
</script>

<style scoped>
</style>
